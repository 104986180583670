@use "../../assets/styles/color"as *;

nav {
    position: fixed;
    left: 20px;
    right: 20px;
    top: 60px;
    height: 45px;
    background-color: $jungle-mist;
    padding: 0 27px;
    border: 1px solid $nepal;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

nav ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    height: 36px;
    margin: 10px 0 0;
}

nav ul li {
    margin-right: 15px;
}

nav ul li button {
    width: 96px;
    height: 100%;
    color: $pickled-bluewood;
    border: 0;
    background-color: unset;
}

nav ul li button.active {
    background-color: $white;
    border: 1px solid $nepal;
    border-bottom: 0;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}

@media screen and (min-width: 450px) {
    nav ul {
        justify-content: flex-start;
    }
}

.secondary-nav {
    position: fixed;
    top: 107px;
    left: 20px;
    right: 20px;
    background-color: $white;
    border: 1px dashed $bismark;
    border-top: 0;
    padding: 10px;
    display: flex;
    justify-content: flex-end;
    z-index: 1;
}

.secondary-nav button {
    border: 1px solid $fiord;
    border-radius: 5px;
    padding: 5px;
}


@media screen and (min-width: 420px) {
    nav, .secondary-nav {
        left: 40px;
        right: 40px;
    }
}
