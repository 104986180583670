@use "../../assets/styles/color" as *;
@use "../../assets/styles/image";

.alert-wrapper {
    position: fixed;
    top: 159px;
    left: 11.5%;
    width: 75%;
    display: flex;
    padding: 1%;
    max-width: 350px;
    border-radius: 5px;
    visibility: hidden;
}

.alert-visible {
    visibility: visible;
}

.alert-error {
    background-color: $chardon;
    color: $scarlet;
    border: 1px solid $scarlet;
}

.alert-msg {
    font-size: 14px;
}

@media screen and (min-width: 768px) {
    .alert-wrapper {
        margin-left: -184px;
        padding: 9px;
        left: 50%;
    }
}