$alabaster: #FBFBFB;
$alice-blue: #F4FBFF;
$bismark: #466C83;
$bittersweet: #FF6F61; 
$black: #000000;
$calypso: #377199;
$chardon: #FFF2F0;
$deep-cerulean: #0079AD;
$deep-sea: #008566;
$fiord: #3E5970;
$jungle-mist: #BDCFDB;
$ming: #3d698f;
$nepal: #85A6BA;
$orient: #005180;
$pickled-bluewood: #293E4C;
$san-marino: #3E769C;
$scarlet: #E02200;
$selago: #EDF3FC;
$steel-blue: #5276B7;
$white: #FFFFFF;
