.caret-down {
    background-image: url('../images/caret-down.svg');
    width: 20px;
    height: 20px;
}

.caret-right {
    background-image: url('../images/caret-right.svg');
    width: 20px;
    height: 20px;
}

.close-icon {
    background-image: url(../images/close.svg);
    width: 16px;
    height: 16px;
    background-size: contain;
}

.tree {
    background: url(../images/tree.svg) no-repeat;
    height: 24px;
    width: 24px;
    background-size: contain;
}
