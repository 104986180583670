@use "./color" as *;

* {
  font-family: 'helvetica neue', helvetica, arial, sans-serif;
}

button {
  cursor: pointer;
}

body {
  background: $alice-blue;
  color: $fiord;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
}

.beautified-wrapper {
  flex-grow: 1;
  padding: 10px;
  overflow: auto;
}

.prop-name {
  font-size: 13px;
  color: $steel-blue;
  border: 0;
  padding: 0;
  background-color: unset;
}

.prop-value-boolean,
.prop-value-object {
  color: $orient;
}

.prop-value-number {
  color: $deep-sea;
}

.prop-value-string {
  color: $bittersweet;
}

span[class^="prop-value"] {
  font-size: 13px;
  font-weight: 400;
  margin-left: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.beautified-wrapper ul {
  list-style-type: square;
  margin: 0;
  padding-left: 27px;
  border-left: 1px dotted;
  margin-left: 3px;
}

.beautified-wrapper ul li {
  position: relative;
}

.beautified-wrapper ul .prop-name:before {
  content: '';
  border-bottom: 1px dotted;
  position: absolute;
  top: 7.5px;
  left: -27px;
  height: 1px;
  width: 15px;
}

.show.show {
  display: block;
}

.hide {
  display: none;
  transition: opacity 0.5s linear;
}

.btn-primary {
  background-color: $fiord;
  color: $white;
  margin-right: 15px;
}

.btn-secondary {
  background-color: $white;
  color: $fiord;
}

.close-btn {
  background-color: unset;
  border: 0;
  margin-left: auto;
  margin-top: 1px;
}

.sr-only { 
  border: 0;
  padding: 0;
  margin: 0;
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px); /*maybe deprecated but we need to support legacy browsers */
  clip-path: inset(50%); /*modern browsers, clip-path works inwards from each corner*/
  white-space: nowrap; /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
}
