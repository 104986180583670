textarea {
    position: absolute;
    top: 0;
    right: 1px;
    bottom: 1px;
    left: 0;
    border: 0;
    padding-top: 4px;
    outline: none;
    resize: none;
    font-size: 12px;
}
