@use "../../assets/styles/color" as *;

main {
    position: absolute;
    top: 152px;
    left: 20px;
    right: 20px;
    bottom: 24px;
    display: flex;
    background-color: $white;
    border: 1px solid $nepal;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-family: 'helvetica neue', helvetica, arial, sans-serif;
    font-size: 12px;
    overflow: hidden;
}

@media screen and (min-width: 420px) {
    main {
        left: 40px;
        right: 40px;
    }
}
