@use "../../assets/styles/color"as *;

.right-panel {
    display: none;
    overflow: auto;
}

table {
    align-self: flex-start;
    width: 100%;
}

.right-panel tbody tr:nth-child(odd) {
    background-color: $selago;
}

.right-panel tbody tr:nth-child(even) {
    background-color: $alabaster;
}

.right-panel table th,
td {
    font-size: 12px;
    min-width: 200px;
    padding: 5px;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    width: 200px;
    word-break: break-word;
}

.right-panel table thead th {
    border-bottom: 2px solid $ming;
    padding-top: 8px;
}

.draggable {
    background-color: $ming;
    min-width: 3px;
}

.drag-preview {
    background-color: $fiord;
    min-width: 4px;
    position: absolute;
    top: 0;
    height: 100%;
    display: none;
}

.draggable:hover {
    cursor: col-resize;
}

@media screen and (min-width: 768px) {
  .right-panel {
    display: flex;
  }
}
