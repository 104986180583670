@use "../../assets/styles/color" as *;

.backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $black;
    opacity: 0.3;
    z-index: 4;
}

.modal {
    position: fixed;
    top: 50%;
    width: 300px;
    left: 50%;
    min-height: 200px;
    max-height: 300px;
    margin-top: -100px;
    margin-left: -150px;
    z-index: 5;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background-color: $jungle-mist;
    color: $pickled-bluewood;
    font-size: 13px;
    height: 30px;
    display: flex;
    padding: 0 6px;
    align-items: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.modal-body {
    background-color: $white;
    font-size: 12px;
    padding: 0 6px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
